/* file upload widget */

.filepond--credits {
  display: none;
}

/* Loading Bullet in BlockUI */

.loading-bullet {
  color: #0099cc !important;
}

/* widgets */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="checkbox"]:focus,
.uneditable-input:focus {
  box-shadow: none;
  border: 2px solid #2684ff;
}

div[role="slider"]:focus,
.uneditable-input:focus {
  /* box-shadow: none; */
  border: 2px solid #2684ff !important;
}

.form-check-input:checked {
  background-color: #2684ff !important;
}

.thumbnailIframe {
  -ms-zoom: 0.5;
  zoom: 0.5;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  transform: scale(0.5);
  transform-origin: 0 0;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #eeeeee;
}

/* @media (max-width: 767.98px) {
  .sidebar {
    top: 0rem;
  }
} */

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

/*
  Login
*/

.div-signin {
  padding-top: 60px;
  padding-bottom: 40px;
  /* background-color: #f5f5f5; */
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.poweredby {
  position: absolute;
  /* bottom: 10px; */
  top: 5px;
  right: 5px;
  /* border: 1px solid #e5e5e5; */
}

.btn-primary {
  border-color: #2684ff !important;
  background-color: #2684ff !important;
}

.btn-primary:hover {
  border-color: #217bf1 !important;
  background-color: #217bf1 !important;
}

.btn-outline-primary {
  border-color: #2684ff !important;
  color: #2684ff !important;
}

.btn-outline-primary:hover {
  border-color: #2684ff !important;
  color: white !important;
  background-color: #2684ff !important;
}

.title-card {
  background-color: rgba(38, 132, 255, 0.04);
}

.title-card:hover {
  background-color: rgba(38, 132, 255, 0.08);
}
